import NextLink, { LinkProps } from 'next/link';
import styles from './styles.module.css';

export type Props = React.PropsWithChildren<LinkProps> & {
  target?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
};

export const Link = ({
  target,
  onClick,
  children,
  disabled,
  ...rest
}: Props): JSX.Element => {
  if (disabled) {
    return (
      // https://w3c.github.io/html-aria/#el-a
      // https://w3c.github.io/html-aria/#el-a-no-href
      <a className={styles.disabled} aria-disabled tabIndex={-1}>
        {children}
      </a>
    );
  }

  return (
    // passHref
    // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
    <NextLink {...rest} passHref>
      <a className={styles.link} {...{ target, onClick }}>
        {children}
      </a>
    </NextLink>
  );
};
