import styles from './styles.module.css';

type ColumnHeaderCellProps = {
  size?: 'small' | 'medium' | 'large';
  justify?: 'start' | 'center' | 'end';
  width?: string;
} & React.ThHTMLAttributes<HTMLTableCellElement>;

export const ColumnHeaderCell = ({
  size = 'medium',
  justify = 'start',
  width,
  ...rest
}: ColumnHeaderCellProps): JSX.Element => {
  const classNames = [
    styles.columnHeaderCell,
    styles[size],
    styles[justify],
  ].join(' ');
  // Overwrite the width of the cell with the width prop.
  const style = { width };
  return <th className={classNames} scope="col" style={style} {...rest} />;
};
