import styled from 'styled-components';

export type ItemProps = {
  children: React.ReactNode;
};

const StyledItem = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;

export const Item = ({ children }: ItemProps): JSX.Element => (
  <StyledItem>{children}</StyledItem>
);

Item.displayName = 'NavigationBar.Item';
