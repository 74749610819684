import { forwardRef } from 'react';
import styles from './styles.module.css';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ children, id, ...rest }, ref) => (
    <span className={styles.radioWrapper}>
      <label htmlFor={id}>
        <input
          className={styles.input}
          type="radio"
          id={id}
          ref={ref}
          {...rest}
        />
        <div className={styles.inputContainer}>
          <span className={styles.radioButton} aria-hidden="true">
            <span />
          </span>
          <span>{children}</span>
        </div>
      </label>
    </span>
  )
);
Radio.displayName = 'Radio';
