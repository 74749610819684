import styled from 'styled-components';
import { Link } from '@/components/new/Link';
import { ChevronIcon } from '@/components/new/icon/ChevronIcon';
import styles from './styles.module.css';

const StyledBacklink = styled.div`
  font-size: 14px;
  margin-right: 16px;
`;

export type BacklinkProps = {
  children: React.ReactNode;
  href: string;
};

export const Backlink = ({ children, href }: BacklinkProps): JSX.Element => (
  <StyledBacklink>
    <Link href={href}>
      <span className={styles.linkWrapper}>
        <ChevronIcon rotation="left" />
        {children}
      </span>
    </Link>
  </StyledBacklink>
);

Backlink.displayName = 'NavigationBar.Backlink';
