import styles from './styles.module.css';

type CellProps = {
  bold?: boolean;
  size?: 'small' | 'medium' | 'large';
  justify?: 'start' | 'center' | 'end';
} & React.TdHTMLAttributes<HTMLTableCellElement>;

export const Cell = ({
  bold = false,
  size = 'medium',
  justify = 'start',
  ...rest
}: CellProps): JSX.Element => {
  const classNames = [
    styles.cell,
    styles[size],
    bold && styles.bold,
    styles[justify],
  ]
    .filter(Boolean)
    .join(' ');
  return <td className={classNames} {...rest} />;
};
