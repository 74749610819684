import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isNotEmpty } from '@/lib/string';

yup.setLocale({
  mixed: {
    required: '入力してください',
  },
  string: {
    length: ({ length }: { length: number }) =>
      `${length}文字で入力してください`,
    max: ({ max }: { max: number }) => `${max}文字以内で入力してください`,
    url: () => '正しいURL形式で入力してください',
  },
});

yup.addMethod<yup.StringSchema>(yup.string, 'iso8601Date', function () {
  return this.transform(function (value) {
    // format check
    if (
      !/^[0-9０-９]{4}[/\-ー]?[0-9０-９]{2}[/\-ー]?[0-9０-９]{2}$/.test(value)
    )
      return value;

    // transform
    const transformed = value
      .replace(/[０-９]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[ー/]/g, '-');

    return transformed.includes('-') || transformed.includes('/')
      ? transformed
      : transformed.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  })
    .matches(
      /^(\d{4}[/-]\d{2}[/-]\d{2})?$/, // emptyはrequiredでチェックすべきなので空文字許容
      'yyyy/mm/dd または yyyy-mm-ddの形式で入力してください'
    )
    .test(
      'iso8601Date',
      '正しい日付を入力してください',
      function (value: string | undefined): boolean {
        if (!value) return true; // emptyはrequiredでチェックすべきなので空文字許容

        const timestamp = Date.parse(value);

        return !isNaN(timestamp);
      }
    );
});

yup.addMethod<yup.StringSchema>(yup.string, 'stripDashes', function () {
  return this.transform(function (value) {
    return value.replace(/-/g, '');
  });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'stripCommas', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    return Number(originalValue.replace(/,/g, ''));
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'officeCardName', function () {
  return this.test(
    'officeCardName',
    '全角15文字、半角30文字以内で入力してください',
    function (value: string | undefined | null): boolean {
      const MAX_COUNT = 30;
      const halfWidthRegex = /[ -~｡-ﾟ]/;

      if (value === undefined || value === null) return true;

      let count = 0;

      [...value].forEach((c) => {
        if (halfWidthRegex.test(c)) {
          count += 1;
        } else {
          count += 2;
        }
      });

      return count <= MAX_COUNT;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'postalCode', function () {
  return this.transform(function (value: string) {
    // emptyはrequiredでチェックすべきなので空文字許容
    return value
      .replace(/[０-９]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[-ー]/g, '');
  }).matches(/^(\d{7})?$/, 'XXX-XXXXの形式または数字7桁で入力してください');
});

yup.addMethod<yup.StringSchema>(yup.string, 'name', function () {
  // 全角文字
  // \u4E00-\u9FAF => "一-龯"
  // \u3041-\u309F => "ぁ-ゟ"
  // \u30A0-\u30FF => "゠-ヿ"
  // \u3005 => "々"
  return this.matches(
    /^([A-Za-z\s]|[\u4E00-\u9FAF]|[\u3041-\u309F]|[\u30A0-\u30FF]|\u3005)+$/,
    '入力してください'
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'nameEn', function () {
  // NOTE: Allow falsy value in order to pass to required validation
  return this.test('nameEn', '英字のみで入力してください', (value) => {
    if (!value) return true;
    return /^[A-Za-z\s]+$/.test(value);
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'nameKana', function () {
  // \u30A0-\u30FF => "゠-ヿ"
  return this.matches(/^[\u30A0-\u30FF\s]+$/, 'カタカナで入力してください');
});

yup.addMethod<yup.StringSchema>(yup.string, 'activationCode', function () {
  return this.matches(
    /^[A-Za-z0-9]{16}$/,
    '16桁の半角英数字で入力してください'
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'halfWidthNumber', function () {
  return this.transform(function (value: string) {
    // emptyはrequiredでチェックすべきなので空文字許容
    if (/^([0-9０-９]+)?$/.test(value)) {
      return value.replace(/[０-９]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    }
    return value;
  }).matches(/^(\d+)?$/, '数字のみで入力してください');
});

// サーバ側が空文字を受け付けないことがあるので、nullに変換する
// 単体だと空文字でinvalidになるので、そうしたくない場合はnullableを使用すること
yup.addMethod<yup.StringSchema>(yup.string, 'emptyToNull', function () {
  return this.transform(function (value) {
    // do nothing for strings
    if (typeof value === 'string' && isNotEmpty(value)) return value;

    // cast everything else to null
    return null;
  });
});

export { yupResolver, yup };
