import styles from './styles.module.css';

type Props = {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
};

export const CircularProgress = ({ size = 'small' }: Props) => {
  const sizeMap = {
    small: 16 / 2,
    medium: 24 / 2,
    large: 40 / 2,
    xlarge: 80 / 2,
  };

  const getStrokeWidth = (size: Props['size']) => {
    switch (size) {
      case 'small':
        return 1.5;
      case 'medium':
        return 2.5;
      case 'large':
        return 3.5;
      case 'xlarge':
        return 4.5;
      default:
        return 1.5;
    }
  };

  const radius = sizeMap[size];
  const strokeWidth = getStrokeWidth(size);
  const normalizedRadius = radius - strokeWidth;

  return (
    <svg className={`${styles.circular} ${styles[size]}`} role="progressbar">
      <circle
        className={`${styles.path} ${styles[size]}`}
        cx={radius}
        cy={radius}
        r={normalizedRadius}
        fill="none"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
