import { Body } from './Body';
import { Cell } from './Cell';
import { ColumnHeaderCell } from './ColumnHeaderCell';
import { Head } from './Head';
import { Root } from './Root';
import { Row } from './Row';
import { RowHeaderCell } from './RowHeaderCell';

export const Table = Object.assign(
  {},
  {
    Root,
    Head,
    Row,
    ColumnHeaderCell,
    Body,
    RowHeaderCell,
    Cell,
  }
);
