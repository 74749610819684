import { forwardRef } from 'react';
import styles from './styles.module.css';

export type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  error?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const TextField = forwardRef<HTMLInputElement, Props>(
  ({ error = false, size = 'medium', ...rest }, ref) => {
    const classNames = [styles.textField, error && styles.error, styles[size]]
      .filter(Boolean)
      .join(' ');
    return <input className={classNames} ref={ref} {...rest} />;
  }
);

TextField.displayName = 'TextField';

export { TextField };
